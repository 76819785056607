
@font-face {
    font-family: '28DaysLater';
    src: url('../../../assets/fonts/28DaysLater.ttf');
}
@font-face {
    font-family: 'EarthKid';
    src: url('../../../assets/fonts/EarthKid.ttf');
}
@font-face {
    font-family: 'SketchRockwell';
    src: url('../../../assets/fonts/SketchRockwell.ttf');
}
.container.draft {
    margin-top:10rem;
}
.row>* {
    --bs-gutter-x: 0.5rem;
}
.text-center {
    text-align: center;
}
.overflow-x-scroll {
    overflow-x: scroll;
}
.font-weight-bold {
    font-weight: bold;
  }
.card {
    --bs-card-color: #fff;
    --bs-card-bg: #000;
    --bs-card-border-width: 3px;
    --bs-card-border-color: #fff;
    
    margin-bottom: 4rem;
    
}
.modal-content {
    --bs-modal-bg: #000;
    --bs-modal-color: #fff;
    --bs-modal-border-width: 3px;
    --bs-modal-border-color: #fff;
}
.activity-modal-close-btn {
    cursor: pointer;
}
.btn {
    --bs-btn-font-family: '28DaysLater';
}
.table {
    --bs-table-color: #fff;
}
.card.team {
    min-height: 32rem;
}
.card.team:focus {
    outline: none;
    min-height: 32rem;
}
.card.current {
    border-color: red;
}
.card.team:hover {
    background-color: #111;
}
h1.title {
    font-family: '28DaysLater';
    font-size: 4rem;
    margin-bottom: 3rem;
}
h1.leaderboard {
    font-family: '28DaysLater';
    font-size: 2rem;
}
a.strava {
    border-color: #fc5200;
    background-color: #fc5200;
}
a.strava:hover {
    border-color: #e34a00;
    background-color: #e34a00;
    color: #fff;
}
.team-logo {
    height: 10rem;
    width: auto;
    margin: auto;
    max-width: 100%;
    padding: 0.3em;
}
.team-delete {
    margin-left: 2rem;
}
.pax-name {
    font-family: 'SketchRockwell';
    font-size: 1rem;
    text-align: left;
}
.pax-logo {
    font-size: 3.8rem;
    height: 3.8rem;
}
.pax-stats {
    font-size: 0.9rem;
    text-align: left;
}
.pax-record {
    padding: 0.5rem;
}
.pax-record:hover {
    background-color: #222;
}
.pax-modal-logo {
    height: 4rem;
}
.popout:hover {
    cursor: pointer;
    /*
    animation: popout 1s ease;
    -webkit-animation: popout 1s ease;
    transform:scale(1.2);
    -webkit-transform:scale(1.2);
    z-index:1000;
    */
}
@keyframes append-animate {
	from {
		transform: scaleY(1);
		opacity: 0;
	}
	to {
		transform: scaleY(0);
		opacity: 1;
	}
}
@keyframes delete-animate {
	from {
		transform: scaleY(1);
		opacity: 1;
	}
	to {
		transform: scaleY(0);
		opacity: 0;
	}
}
.card-text.show {
    animation: append-animate .3s linear;
    
}
.card-text.hide {
    animation: delete-animate .3s linear;
}
.team-title {
    font-family: '28DaysLater';
    text-align: center;
}
.team-title .round {
    font-size: 2rem;
}
.cap-name {
    font-family: '28DaysLater';
    font-size: 2rem;
    margin-top: 2rem;
}
.team .pax-name, .team .pax-stats {
    font-size: 1rem;
    font-family: 'SketchRockwell';

}
.team .pax-record {
    margin: 1rem 0;
}
.team-title.leaders {
    font-size: 1.2rem;
}
.add-team-icon {
    text-align: center;
    font-size: 15rem;
}
@keyframes popout {
    from{transform:scale(1)}
    to{transform:scale(1.2)}
}
@-webkit-keyframes popout {
    from{-webkit-transform:scale(1)}
    to{-webkit-transform:scale(1.2)}
}